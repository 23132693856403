import React from 'react'
import Pig from 'react-pig'
import imageData from './mcfrench.json'

const App = () => (
  <main className="main">
    <h1>Nick &amp; Tess in Amsterdam</h1>
    <Pig
      imageData={imageData}
      gridGap={4}
      bgColor="hsla(211, 50%, 98%)"
      groupGapLg={50}
      groupGapSm={20}
      breakpoint={800}
      groupByDate
    />
  </main>
)

export default App
